import React from 'react'
import { Link } from 'gatsby'
import { Col, Row } from 'react-bootstrap'
import Layout from '/src/components/layout'
import HeroImage from '/src/components/layout/HeroImage'

const TubePipePage = () => {
  return (
    <Layout hero={<HeroImage title="Tube & Pipe" />} mainContainer={{fluid: "xxl"}}>
      <div className="content-row-container">
        <Row className="d-flex justify-content-center">
          <Col sm={10}>
            <Row className="mb-4">
              <Col sm={12}>
                <p>
                  Steel tube and pipe projects demand precision, consistency and reliability. 
                  That means partnering with a steel distributor and manufacturer with a proven track record and specialist knowledge to deliver exceptional results. 
                  We specialise in manufacturing structural and precision RHS, CHS & SHS tube and pipe. 
                  Every length of steel that leaves our mills passes our quality management system which is certified to ISO 9001 and 
                  test facilities accredited to ISO/IEC 17025. When you partner with Orrcon steel, you are partnering with a steel, 
                  tube and pipe specialist that will deliver the quality products you need.
                </p>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row className="d-flex justify-content-center content-row single">
          <Col xs={10}>
            <Row className="d-flex align-items-center">
              <Col sm={3}>
                <div className="title-container p-3">
                  <Link to="/product-info/tube-pipe">
                    <h2 className="title">Tube & Pipe</h2>
                  </Link>
                </div>
              </Col>
              <Col sm={9}>
                <Row>
                  <Col sm={6}>
                    <div className="linked-list info">
                      <Link to="#" className="mb-2">
                        Structural
                      </Link>
                      <ul>
                        <li>
                          <Link to="/product-info/tube-pipe/structural/circular-hollow-section" className="mb-2">
                            Circular Hollow Section
                          </Link>
                        </li>
                        <li>
                          <Link to="/product-info/tube-pipe/structural/rectangular-hollow-section" className="mb-2">
                            Rectangular Hollow Section
                          </Link>
                        </li>
                        <li>
                          <Link to="/product-info/tube-pipe/structural/sprinkler-and-hydrant-pipe" className="mb-2">
                            Sprinkler And Hydrant Pipe
                          </Link>
                        </li>
                        <li>
                          <Link to="/product-info/tube-pipe/structural/square-hollow-section" className="mb-2">
                            Square Hollow Section
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </Col>
                  <Col sm={6}>
                    <div className="linked-list info">
                      <Link to="#" className="mb-2">
                        Precision
                      </Link>
                      <ul>
                        <li>
                          <Link to="/product-info/tube-pipe/precision/circular-pipe" className="mb-2">
                            Circular Pipe
                          </Link>
                        </li>
                        <li>
                          <Link to="/product-info/tube-pipe/precision/oval-tube" className="mb-2">
                            Oval Tube Steel
                          </Link>
                        </li>
                        <li>
                          <Link to="/product-info/tube-pipe/precision/rectangular-tube" className="mb-2">
                            Rectangular Tube
                          </Link>
                        </li>
                        <li>
                          <Link to="/product-info/tube-pipe/precision/special-shapes" className="mb-2">
                            Special Shapes
                          </Link>
                        </li>
                        <li>
                          <Link to="/product-info/tube-pipe/precision/square-tube" className="mb-2">
                            Square Tube
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </Col>
                </Row>
                <Row className="mt-3">
                  <Col sm={6}>
                    <div className="content info">
                      <Link to="/product-info/tube-pipe/broadspec-pipe" className="mb-2">
                        Broadspec Pipe
                      </Link>
                      <p>BROADSPEC® offers a range of structural steel tubulars and pressure pipe products that comply with multiple industry standards.</p>
                    </div>
                    <div className="content info">
                      <Link to="/product-info/tube-pipe/yard-rail" className="mb-2">
                        Yard Rail
                      </Link>
                      <p>Orrcon Steel's Australian Made yard rail is a safe and economical steel solution for managing and protecting valuable livestock.</p>
                    </div>
                  </Col>
                  <Col sm={6}>
                    <div className="content info">
                      <Link to="/product-info/tube-pipe/hot-dipped-galvanised" className="mb-2">
                        Hot Dipped Galvanised
                      </Link>
                      <p>Hot Dip Galvanised finished products are perfect for jobs that require longer lasting protection against all weather and all conditions.</p>
                    </div>
                    <div className="content info">
                      <Link to="/product-info/tube-pipe/silo-section" className="mb-2">
                        Silo Section
                      </Link>
                      <p>A unique profile used to replace RHS in the Silo base ring. Available in our ALLGAL® finish.</p>
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
    </Layout>
  )
}

export default TubePipePage

export const Head = () => <title>Tube & Pipe</title>
